/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//const fonts = require ('./src/styles/fonts/fonts.scss');

//const React = require('react');

// const { PageContextProvider } = require('./src/components/context/PageContext');

// exports.wrapPageElement = ({ element, props }) => {
//   // //console.log('>>>>>>>>>>>>>>>> exports.wrapPageElement');
//   ////console.log('>>>>>>>>>>>>>>>> exports.+++++');
//   return <PageContextProvider {...props}>{element}</PageContextProvider>;
// };

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   // //console.log('new pathname', location.pathname);
//   // //console.log('old pathname', prevLocation ? prevLocation.pathname : null);
// };

import './src/styles/fonts/fonts.scss';
import './src/styles/globals.scss';

import React from 'react';

import { PageContextProvider } from './src/components/context/PageContext';

export const wrapPageElement = ({ element, props }) => {
  return <PageContextProvider {...props}>{element}</PageContextProvider>;
};
