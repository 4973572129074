import { PageProps } from 'gatsby';
import React, { useState, useContext, createContext, useEffect } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';

interface ICart {
  productList: any[];
}

interface IPathState {
  path: string;
}

interface IMenuState {
  menuStateOpen: boolean;
}

interface ICartDisplay {
  cartDisplayOn: boolean;
}

interface IPageContext {
  cart: ICart;
  setCart: (value: ICart) => void;

  cartDisplay: ICartDisplay;
  setCartDisplay: (value: ICartDisplay) => void;

  menuState: IMenuState;
  setMenuState: (value: IMenuState) => void;

  pathState: IPathState;
  setPathState: (value: IPathState) => void;
}
const PageContext = createContext<IPageContext | undefined>(undefined);

export const PageContextProvider = (props: PageProps) => {
  const [cart, setCart] = useLocalStorage('cart', {
    productList: [],
  });
  const [pathState, setPathState] = useState<IPathState>({
    path: props.location.pathname,
  });

  const [menuState, setMenuState] = useState<IMenuState>({
    menuStateOpen: false,
  });

  const [cartDisplay, setCartDisplay] = useState<ICartDisplay>({
    cartDisplayOn: false,
  });

  //console.log('menuState ', menuState);

  return (
    <PageContext.Provider
      value={{
        pathState: {
          path: props.location.pathname,
        },
        setPathState: setPathState,
        cart: cart,
        setCart: setCart,
        menuState: menuState,
        setMenuState: setMenuState,
        cartDisplay: cartDisplay,
        setCartDisplay: setCartDisplay,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);
  if (!pageContext)
    throw new Error(
      'No PageContext.Provider found when calling usePageContext.',
    );
  return pageContext;
};
